import shaneIcon from './images/shaneKaraconect_small.png';
import sorchaIcon from './images/SorchaMulliganIcon.png';
import liaIcon from './images/liaBoylanIcon.png';
import nancyOderaIcon from './images/NancyOdera.png';
import fredericoGatehouseChambersIcon from './images/icon-fredericoGatehouseChambers.jpg';

export const carouselItems = [
  {
    id: 'parentFredericoSingarajah',
    imgSrc: fredericoGatehouseChambersIcon,
    nameUpper: 'Barrister & Arbitrator',
    nameLower: 'Gatehouse Chambers',
    paragraph:
      '“As a doctor and a barrister, our childcare gaps were pretty crippling. We can’t bring children to work or ‘muddle through’ at home. We had most of the childcare sorted, but there were a few hours each afternoon, every afternoon involving a school pick-up that we just couldn’t make work. öogo found us someone for whom the 15 hours a week was the perfect fit. Those 15 hours are the difference between the work going well or the week falling apart. Thanks, öogo!”',
  },
  {
    id: 'parentNancyOdera',
    imgSrc: nancyOderaIcon,
    nameUpper: 'Nancy Odera - Program Manager',
    nameLower: 'Meta',
    paragraph:
      '“When I moved back to Ireland from abroad, I went from having a lot of family support to having none. It was a challenging transition, especially while trying to enjoy the last few weeks of my maternity leave. The thought of searching for reliable childcare was overwhelming and made me feel incredibly isolated. But then I discovered öogo. They quickly found five suitable minders, making the childcare search far less daunting and lonely. Thanks to öogo, I was able to feel like myself again, not just a mum, and I could return to work with peace of mind knowing my child was in good hands.”',
  },
  {
    id: 'parentSorchaMulligan',
    imgSrc: sorchaIcon,
    nameUpper: 'Sorcha Mulligan',
    nameLower: 'Venture Partner - FinTech',
    paragraph:
      '“Without the amazing childcare facilities and team from öogo I would not have been there-one more empty seat where a Mum should have been.”',
  },
  {
    id: 'parentLiaBoyland',
    imgSrc: liaIcon,
    nameUpper: 'Lia Boyland - CEO and Co-Founder',
    nameLower: 'Talent Shake',
    paragraph:
      "“Thanks to the öogo team, I was able to attend an event that I usually wouldn't have been able to as a founder and mom with a 4-year-old and a 7-month-old baby. It's not that childcare isn't available; it's because I don't want to leave my baby for a full day yet.”",
  },
  {
    id: 'parentKaraconect1',
    imgSrc: shaneIcon,
    nameUpper: 'Shane Cusack - Account Manager',
    nameLower: 'Kara Connect',
    paragraph:
      '“As parents of three young children, myself and my wife constantly found ourselves with the usual childcare gaps and challenges. Little pockets of time when the childcare had fallen through or we didn’t have any. We didn’t really think that finding ad-hoc childcare for “little pockets of time” was something that could be achieved but through öogo, we have been connected to three really great Minders™ who live close by and for whom occasional work is ideal.”',
  },
];
