import React, { useEffect, useRef, useState, useCallback } from 'react';
import { bool, object, arrayOf, func } from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import { loadListingCarousel } from '../../containers/SearchClaimPage/SearchClaimPage.duck';
import ListingCard from '../ListingCard/ListingCard';
import { intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { useConfiguration } from '../../context/configurationContext';
import css from './MoreListingsCarousel.module.css';
import classNames from 'classnames';
import Pagination from './Pagination';

const MoreListingsCarousel = props => {
  const {
    className,
    listings,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    loadListingCarousel,
    id,
    hasSeparator,
    listingsCarouselPagination,
  } = props;
  const config = useConfiguration();

  const carouselRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [totalDots, setTotalDots] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isScrolling, setIsScrolling] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [temporaryListings, setTemporaryListings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const classNameRoot = classNames(className || css.root);

  const fetchListings = useCallback(async () => {
    const params = {
      filterId: 'group_pregnancy_birth',
      page: currentPage,
    };
    setIsLoading(true);
    await loadListingCarousel(params, '', config);
    setIsLoading(false);
  }, [loadListingCarousel, config, currentPage]);

  useEffect(() => {
    fetchListings();
  }, [fetchListings]);

  useEffect(() => {
    if (!queryListingsCarouselInProgress && listings.length > 0) {
      setTemporaryListings(listings);
      setCurrentIndex(0);
      if (carouselRef.current) {
        carouselRef.current.scrollTo({ left: 0, behavior: 'auto' }); // Reset scroll position
      }
    }
  }, [listings, queryListingsCarouselInProgress]);

  const calculateTotalDots = useCallback(() => {
    if (carouselRef.current && temporaryListings.length > 0) {
      const card = carouselRef.current.firstChild;
      const cardWidth = card ? card.offsetWidth : 200;
      const cardMargin = 20;
      const fullCardWidth = cardWidth + cardMargin;
      const visibleWidth = carouselRef.current.offsetWidth;
      const totalWidth = temporaryListings.length * fullCardWidth;
      const dots = Math.ceil((totalWidth - visibleWidth) / fullCardWidth) + 1;
      setTotalDots(dots);
    }
  }, [temporaryListings]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      calculateTotalDots();
      const handleResize = () => {
        calculateTotalDots();
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [calculateTotalDots]);

  useEffect(() => {
    calculateTotalDots();
  }, [temporaryListings, calculateTotalDots]);

  useEffect(() => {
    const handleScrollEnd = () => {
      setIsScrolling(false);
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener('scroll', handleScrollEnd, { passive: true });

      return () => {
        if (carouselRef.current) {
          carouselRef.current.removeEventListener('scroll', handleScrollEnd);
        }
      };
    }
  }, []);

  const scrollCarousel = direction => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const card = carousel.firstChild;
      const cardWidth = card ? card.offsetWidth : 200;
      const cardMargin = 20;
      const fullCardWidth = cardWidth + cardMargin;
      const maxScrollLeft = carousel.scrollWidth - carousel.clientWidth;

      setIsScrolling(true);
      if (direction === 'right') {
        if (currentIndex >= totalDots - 1) {
          carousel.scrollTo({ left: 0, behavior: 'smooth' });
          setCurrentIndex(0);
        } else {
          carousel.scrollBy({ left: fullCardWidth, behavior: 'smooth' });
          setCurrentIndex(prevIndex => prevIndex + 1);
        }
      } else if (direction === 'left') {
        if (currentIndex <= 0) {
          carousel.scrollBy({ left: maxScrollLeft, behavior: 'smooth' });
          setCurrentIndex(totalDots - 1);
        } else {
          carousel.scrollBy({ left: -fullCardWidth, behavior: 'smooth' });
          setCurrentIndex(prevIndex => prevIndex - 1);
        }
      }
    }
  };

  const handleScroll = () => {
    if (isScrolling) return;

    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const scrollLeft = carousel.scrollLeft;
      const totalScrollWidth = carousel.scrollWidth - carousel.clientWidth;
      const scrollRangePerDot = totalScrollWidth / (totalDots - 1);
      const newIndex = Math.round(scrollLeft / scrollRangePerDot);

      setCurrentIndex(newIndex);
    }
  };

  const handleDotClick = index => {
    if (carouselRef.current) {
      const card = carouselRef.current.firstChild;
      const cardWidth = card ? card.offsetWidth : 200;
      const cardMargin = 20;
      const fullCardWidth = cardWidth + cardMargin;
      const newScrollLeft = index * fullCardWidth;

      setIsScrolling(true);
      carouselRef.current.scrollTo({ left: newScrollLeft, behavior: 'smooth' });
      setCurrentIndex(index);
    }
  };

  const isElementInViewport = el => {
    if (typeof window !== 'undefined') {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }
    return false;
  };

  const checkVisibility = () => {
    if (carouselRef.current) {
      setIsVisible(isElementInViewport(carouselRef.current));
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      checkVisibility();
      window.addEventListener('scroll', checkVisibility);
      window.addEventListener('resize', checkVisibility);

      return () => {
        window.removeEventListener('scroll', checkVisibility);
        window.removeEventListener('resize', checkVisibility);
      };
    }
  }, []);

  // Auto-scroll feature
  useEffect(() => {
    let autoScroll;
    if (typeof window !== 'undefined' && !isHovered && !isTouched && isVisible) {
      setIsScrolling(true);
      autoScroll = setInterval(() => {
        if (currentIndex === totalDots - 1) {
          setCurrentIndex(0);
          carouselRef.current.scrollTo({ left: 0, behavior: 'smooth' });
        } else {
          scrollCarousel('right');
        }
      }, 2500);
    }

    return () => {
      if (autoScroll) {
        clearInterval(autoScroll);
        setIsScrolling(false);
      }
    };
  }, [isHovered, isTouched, isVisible, currentIndex, totalDots, scrollCarousel]);

  const handlePageChange = page => {
    setCurrentPage(page);
  };

  if (queryListingsCarouselError) {
    return <div>Error loading listings</div>;
  }

  return (
    <div className={classNameRoot} id={id}>
      {hasSeparator && <span className={css.separator}></span>}
      <div
        className={css.carouselContainer}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onTouchStart={() => setIsTouched(true)}
        onTouchEnd={() => setIsTouched(false)}
      >
        <h3>
          More Listings in <span className={css.textBlue}>Sleep</span>
        </h3>
        {temporaryListings && temporaryListings.length > 0 ? (
          <>
            <div className={css.carouselControls}>
              <button
                className={css.carouselButton}
                onClick={() => {
                  scrollCarousel('left');
                }}
              >
                &#9664;
              </button>
              <button
                className={css.carouselButton}
                onClick={() => {
                  scrollCarousel('right');
                }}
              >
                &#9654;
              </button>
            </div>
            <div
              className={`${css.carousel} ${isLoading ? css.loading : ''}`}
              ref={carouselRef}
              onScroll={handleScroll}
            >
              {temporaryListings.map(listing => (
                <ListingCard key={listing.id.uuid} listing={listing} className={css.listingCard} />
              ))}
            </div>
            <div className={css.carouselIndicators}>
              {Array.from({ length: totalDots }).map((_, index) => (
                <div
                  key={index}
                  className={`${css.carouselIndicator} ${index === currentIndex ? css.active : ''}`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
            <Pagination
              totalPages={listingsCarouselPagination.totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </>
        ) : (
          <div>No listings available</div>
        )}
      </div>
    </div>
  );
};

MoreListingsCarousel.defaultProps = {
  hasSeparator: true,
  listingsCarouselPagination: null,
};

MoreListingsCarousel.propTypes = {
  queryListingsCarouselInProgress: bool.isRequired,
  queryListingsCarouselError: bool,
  loadListingCarousel: func.isRequired,
  intl: intlShape.isRequired,
  hasSeparator: bool,
  listingsCarouselPagination: propTypes.pagination,
};

const mapStateToProps = state => {
  const {
    listingsCarousel,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    listingsCarouselPagination,
  } = state.SearchClaimPage;

  const listings = listingsCarousel;
  return {
    listings,
    queryListingsCarouselInProgress,
    queryListingsCarouselError,
    listingsCarouselPagination,
  };
};

const mapDispatchToProps = dispatch => ({
  loadListingCarousel: (params, search, config) =>
    dispatch(loadListingCarousel(params, search, config)),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MoreListingsCarousel)
);
