import raivo from './images/raivo.png';
import susan from './images/susan.png';
import charlotte from './images/charlotte.png';

export const carouselItems = [
  {
    id: 'Raivo_Ruikis',
    imgSrc: raivo,
    nameUpper: 'Raivo Ruikis',
    nameLower: null,
    paragraph:
      "“I used to teach coding to a young guy whose Mum wanted him to learn something new because he was falling in with the wrong crowd. He really took to coding and his Mum used to mention that he was doing so much better with something positive and productive to focus on. I guess that's when I realised that opening up kids' minds to learning cool new skills was a real gift.”",
  },
  {
    id: 'Susan',
    imgSrc: susan,
    nameUpper: 'Susan',
    nameLower: null,
    paragraph:
      "“When I was nursing in the children's ward, parents weren't allowed to stay with their children overnight. I used to sit down beside a little one who was upset and whisper into their ear \"it's good to let the adults go home because the fairies only come out at nighttime if there are no adults here\". That was a super distraction and questions about fairies were plentiful enough to get to heavy eyes pretty quickly.”",
  },
  {
    id: 'Charlotte',
    imgSrc: charlotte,
    nameUpper: 'Charlotte',
    nameLower: null,
    paragraph:
      "“One of the nicest memories I have was when a single Mum who had her baby during COVID reached out to me for help. I came over and she handed me the newborn and went to bed. I didn't know the baby from before but immediately felt so connected to her. She stayed awake for ages in my arms, totally content and everything was so pure and serene in the room with her for ages.”",
  },
];
