import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import css from './SectionMoreTestimonials.module.css';
import ImageWithTextCarousel from '../../../components/ImageWithTextCarousel/ImageWithTextCarousel';
import { carouselItems } from './carouselItems';

const SectionMoreTestimonials = () => {
  const title = <FormattedMessage id="CorporatePageEsg.SectionMoreTestimonials.title" />;

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionInner}>
        <div className={css.sectionWrapper}>
          <h2>{title}</h2>
          <div className={css.content}>
            <div className={css.carouselWrapper}>
              <ImageWithTextCarousel carouselItems={carouselItems} id="corporateTestimonials" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionMoreTestimonials.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionMoreTestimonials.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionMoreTestimonials;
