import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import classNames from 'classnames';

import LandingHeroAlien from './landing_hero_alien.svg';
import dogPatchLogo from './logos/dogpatch.png';
import aibLogo from './logos/aib.png';
import bitpandaLogo from './logos/bitpanda.png';
import zalandoLogo from './logos/zalando.png';
import accentureLogo from './logos/accenture.png';
import leasePlanLogo from './logos/leaseplan.png';

import css from './SectionHeroHome.module.css';

import { NamedLink } from '../../../components';

const SectionHeroHome = props => {
  const [mounted, setMounted] = useState(false);
  const { rootClassName, className } = props;

  useEffect(() => {
    setMounted(true);
  }, []);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <div className={css.heroTextContent}>
          <h2 className={classNames(css.heroMainTitle, { [css.heroMainTitleFEDelay]: mounted })}>
            <FormattedMessage id="LandingPage.SectionHeroHome.title">
              {text => <div style={{ whiteSpace: 'pre-line' }}>{text}</div>}
            </FormattedMessage>
          </h2>
          <p className={classNames(css.heroSubTitle, { [css.heroSubTitleFEDelay]: mounted })}>
            <FormattedMessage id="LandingPage.SectionHeroHome.subtitle" />
          </p>
          <div className={css.heroActions}>
            <NamedLink
              name={'BecomeMinderPage'}
              className={classNames(css.heroFirstButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="LandingPage.SectionHeroHome.button1" />
            </NamedLink>
            <NamedLink
              name={'ParentsPage'}
              className={classNames(css.heroFirstButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="LandingPage.SectionHeroHome.button2" />
            </NamedLink>
            <NamedLink
              name={'CorporateEsgPage'}
              className={classNames(css.heroFirstButton, { [css.heroButtonFEDelay]: mounted })}
            >
              <FormattedMessage id="LandingPage.SectionHeroHome.button3" />
            </NamedLink>
          </div>
        </div>
        <div className={css.heroAlien}>
          <img
            loading="lazy"
            height="262px"
            width="250px"
            src={LandingHeroAlien}
            alt="öogo alien"
          />
        </div>
        <div className={css.wrapMottoVideo}>
          <div className={css.motto}>
            <FormattedMessage id="LandingPage.SectionHeroHome.motto" />
          </div>
          <div className={css.heroVideoContainer} />
        </div>
      </div>{' '}
      <hr className={css.grayLine} /> {/* Gray line */}
      <div className={css.iconsTitle}>
        <p>Foward-thinking organisations trust öogo</p>
      </div>
      <div className={css.iconSection}>
        <div className={css.iconPlaceholder}>
          <img src={zalandoLogo} alt="Zalando" className={css.icon} />
        </div>
        <div className={css.iconPlaceholder}>
          <img src={dogPatchLogo} alt="Dogpatch Labs" className={css.icon} />
        </div>
        <div className={css.iconPlaceholder}>
          <img src={leasePlanLogo} alt="LeasePlan" className={css.icon} />
        </div>
        <div className={css.iconPlaceholder}>
          <img src={accentureLogo} alt="Accenture" className={css.icon} />
        </div>
        <div className={css.iconPlaceholder}>
          <img src={aibLogo} alt="AIB" className={css.icon} />
        </div>
      </div>
    </div>
  );
};

SectionHeroHome.defaultProps = { rootClassName: null, className: null };

SectionHeroHome.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHeroHome;
