import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import css from './SectionCarousel.module.css';
import ImageWithTextCarousel from '../../../components/ImageWithTextCarousel/ImageWithTextCarousel';
import { carouselItems } from './carouselItems';
import { NamedLink } from '../../../components';

const SectionCarousel = props => {
  const { isCurrentUserParent } = props;
  const title = <FormattedMessage id="BecomeMinderPage.SectionCarousel.title" />;
  const paragraph = <FormattedMessage id="BecomeMinderPage.SectionCarousel.paragraph" />;
  const button = <FormattedMessage id="BecomeMinderPage.SectionCarousel.button" />;

  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionInner}>
        <div className={css.sectionWrapper}>
          <h2>{title}</h2>
          <div className={css.content}>
            <div className={css.carouselWrapper}>
              <ImageWithTextCarousel carouselItems={carouselItems} id="becomeMinderCarousel" />
            </div>
            <div className={css.separator} />
            <div className={css.contentText}>
              <p>{paragraph}</p>
              {!isCurrentUserParent && (
                <NamedLink name="NewListingPage" className={css.becomeMinderButton}>
                  {button}
                </NamedLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SectionCarousel.defaultProps = { rootClassName: null, className: null, isCurrentUserParent: false };

const { string, bool } = PropTypes;

SectionCarousel.propTypes = {
  rootClassName: string,
  className: string,
  isCurrentUserParent: bool,
};

export default SectionCarousel;
