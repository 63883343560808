import React from 'react';
import loadable from '@loadable/component';

import { bool, object } from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { camelize } from '../../util/string';
import { propTypes } from '../../util/types';

import FallbackPage from './FallbackPage';
import { ASSET_NAME } from './LandingPage.duck';

import SectionHeroHome from './SectionHeroHome/SectionHeroHome';

import css from './LandingPage.module.css';
import classNames from 'classnames';
import { NamedRedirect } from '../../components';
import MoreListingsCarousel from '../../components/MoreListingsCarousel/MoreListingsCarousel';
import SectionCarousel from '../BecomeMinderPage/SectionCarousel/SectionCarousel';
import SectionChildcareLayers from '../CorporateEsgPage/SectionChildcareLayers/SectionChildcareLayers';
import SectionMoreTestimonials from './SectionMoreTestimonials/SectionMoreTestimonials';

const PageBuilder = loadable(() =>
  import(/* webpackChunkName: "PageBuilder" */ '../PageBuilder/PageBuilder')
);

const SectionHeroDefault = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={classNames(css.section, css.hero)}>
      <SectionHeroHome />
    </section>
  );
};
const SectionMoreListingsCarouselDefault = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={classNames(css.sectionMoreListings)}>
      <MoreListingsCarousel
        id="moreListings"
        className={css.moreListingsCarousel}
        hasSeparator={false}
      />
    </section>
  );
};
const SectionCarouselDefault = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={classNames(css.section, css.carouselMinderSection)}>
      <SectionCarousel />
    </section>
  );
};
const SectionMoreTestimonialsDefault = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={classNames(css.section, css.moreTestimonialsSection)}>
      <SectionMoreTestimonials />
    </section>
  );
};
const SectionChildcareLayersDefault = props => {
  const { sectionId } = props;
  return (
    <section id={sectionId} className={classNames(css.section, css.childcareLayersSection)}>
      <SectionChildcareLayers />
    </section>
  );
};

export const LandingPageComponent = props => {
  const {
    pageAssetsData,
    inProgress,
    error,
    /* custom */
    isAuthenticated,
    isCurrentUserParent,
    currentUser,
  } = props;

  const pageData = pageAssetsData?.[camelize(ASSET_NAME)]?.data;

  const sectionHeroHome = {
    sectionId: 'hero-home-section',
    sectionType: 'customHeroHome',
  };
  const sectionMoreListingsCarousel = {
    sectionId: 'more-listinsg-carousel-section',
    sectionType: 'moreListingsCarousel',
  };
  const sectionMinderTestimonial = {
    sectionId: 'Minder-Carousel-section',
    sectionType: 'carouselMinder',
    isCurrentUserParent: isCurrentUserParent,
  };
  const sectionMoreTestimonials = {
    sectionId: 'more-testimonials-section',
    sectionType: 'customMoreTestimonials',
  };
  const sectionChildcareLayers = {
    sectionId: 'childcare-layers-section',
    sectionType: 'customChildcareLayers',
  };

  const isParentDashboard = isAuthenticated && isCurrentUserParent;
  const hasNoDashboard =
    isParentDashboard && currentUser?.attributes?.profile?.privateData?.signupInfo?.noDashboard;
  const isOogoEvent =
    currentUser?.attributes?.profile?.privateData?.signupInfo?.signupOrigin === 'oogoEvent';

  let customSections = [];

  if ((isParentDashboard && !hasNoDashboard) || isOogoEvent) {
    return <NamedRedirect name="DashboardBasePage" />;
  }
  if (isParentDashboard && hasNoDashboard) {
    customSections = customSections.concat(sectionHeroHome, sectionMoreListingsCarousel);
  } else {
    customSections = customSections.concat(
      sectionHeroHome,
      sectionChildcareLayers,
      sectionMoreTestimonials,

      sectionMinderTestimonial
    );
  }

  return (
    <PageBuilder
      pageAssetsData={{
        ...pageData,
        sections: customSections,
      }}
      options={{
        sectionComponents: {
          customHeroHome: { component: SectionHeroDefault },
          moreListingsCarousel: { component: SectionMoreListingsCarouselDefault },
          carouselMinder: { component: SectionCarouselDefault },
          customMoreTestimonials: { component: SectionMoreTestimonialsDefault },
          customChildcareLayers: { component: SectionChildcareLayersDefault },
        },
      }}
      inProgress={inProgress}
      error={error}
      fallbackPage={<FallbackPage error={error} />}
    />
  );
};
LandingPageComponent.defaultProps = {
  isCurrentUserParent: false,
};
LandingPageComponent.propTypes = {
  pageAssetsData: object,
  inProgress: bool,
  error: propTypes.error,
  /* custom */
  isCurrentUserParent: bool,
};

const mapStateToProps = state => {
  const { pageAssetsData, inProgress, error } = state.hostedAssets || {};
  const { isAuthenticated } = state.auth;
  const { isCurrentUserParent, currentUser } = state.user;
  return {
    pageAssetsData,
    inProgress,
    error,
    isAuthenticated,
    isCurrentUserParent,
    currentUser,
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(connect(mapStateToProps))(LandingPageComponent);

export default LandingPage;
